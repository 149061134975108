/* eslint-disable @typescript-eslint/no-use-before-define */
import axios from 'axios';
import { AUTH_TOKEN } from 'utils/constants';
import { singleSignIn } from 'utils/SSO';
import { i18n } from './i18n';

const { envVars } = require('next/config').default().publicRuntimeConfig;

const { API_URL } = envVars;

axios.interceptors.request.use(
  (config) => {
    const authHeader = {};

    if (typeof window !== 'undefined') {
      // eslint-disable-next-line no-param-reassign
      config.params = {
        preferred_seo_identifer: window.location.pathname,
        ...config.params,
      };
      const authToken = localStorage.getItem(AUTH_TOKEN);
      if (authToken) {
        authHeader.Authorization = `Bearer ${authToken}`;
      }
    }
    return {
      ...config,
      baseURL: API_URL,
      headers: {
        ...config.headers,
        'Accept-Language': i18n.language || config.headers['Accept-Language'],
        ...authHeader,
      },
    };
  },
  (err) => Promise.reject(err)
);

export default axios;

export const API = {
  seoValues: '/page_seo_values',
  authenticityToken: '/authenticity_token',
  countries: {
    change: '/countries/change',
    get: '/countries',
  },
  currencies: {
    index: '/currencies',
    change: 'currencies/change',
  },
  owner: {
    dashboard: '/user/dashboard',
    financials: '/owner/financials',
    payouts: {
      index: '/owner/payouts',
      unsettledPaymentDistributions: '/owner/payouts/unsettled_payment_distributions',
    },
    earningsAndPayouts: '/owner/earnings_and_payouts',
    payoutTransferMethods: {
      bankAccounts: '/owner/bank_accounts',
    },
  },
  homepage: '/homepage',
  riderIdentityVerification: {
    riderIdVerification:
      '/pickup/vehicle_reservations/{vehicle_reservation_id}/rider_identity_verification',
    rivAddDoc: '/pickup/vehicle_reservations/{vehicle_reservation_id}/rider_identity_documents',
    rivDeleteDoc:
      '/pickup/vehicle_reservations/{vehicle_reservation_id}/rider_identity_documents/{id}',
    sendReminder: '/vehicle_reservations/{vehicle_reservation_id}/send_check_in_reminder',
    start: '/pickup/vehicle_reservations/{id}/start',
    riderInfo: '/pickup/vehicle_reservations/{id}/rider',
    note: '/pickup/vehicle_reservations/{vehicle_reservation_id}/rider_identity_verification/notes',
    addDocument: '/pickup/vehicle_reservations/{vehicle_reservation_id}/inspection_documents',
    deleteDocument:
      '/pickup/vehicle_reservations/{vehicle_reservation_id}/inspection_documents/{id}',
    optionalUpsells: '/pickup/vehicle_reservations/{vehicle_reservation_id}/optional_upsells',
    safetyCheck: '/pickup/vehicle_reservations/{vehicle_reservation_id}/safety_check_items',
    notifySigneture: '/pickup/vehicle_reservations/{vehicle_reservation_id}/contract/notify',
    waitingSigneture: '/pickup/vehicle_reservations/{id}/waiting_for_signature',
    checkForCompiletion:
      '/pickup/vehicle_reservations/{vehicle_reservation_id}/contract/check_contract_completion',
    tripIssues: '/pickup/vehicle_reservations/{vehicle_reservation_id}/trip_issues/{id}',
    tripIssue: '/pickup/vehicle_reservations/{vehicle_reservation_id}/trip_issues',
    withdrawContract: '/pickup/vehicle_reservations/{id}/withdraw_contract',
    contractInfo: '/pickup/vehicle_reservations/{vehicle_reservation_id}/contract/info',
    riderBikeInspection:
      '/pickup/vehicle_reservations/{vehicle_reservation_id}/rider_bike_inspection',
    riderSafetyCheck:
      '/pickup/vehicle_reservations/{vehicle_reservation_id}/contract/safety_check_items',
    riderIssues: '/pickup/vehicle_reservations/{vehicle_reservation_id}/contract/issues',
    contractAgreement: '/pickup/vehicle_reservations/{vehicle_reservation_id}/contract/agreement',
    contractConfirm: '/pickup/vehicle_reservations/{vehicle_reservation_id}/contract/confirm',
    ownerBikeInspection:
      '/pickup/vehicle_reservations/{vehicle_reservation_id}/owner_bike_inspection',
    pickup: '/pickup/vehicle_reservations/{id}/pickup',
    paymentSummary:
      '/pickup/vehicle_reservations/{vehicle_reservation_id}/quote_and_payment_summary',
    paymentHistory: '/vehicle_reservations/{vehicle_reservation_id}/payments',
    refundBalance: '/pickup/vehicle_reservations/{vehicle_reservation_id}/refund_balance',
  },
  dropoffProcess: {
    start: '/dropoff/vehicle_reservations/{id}/start',
    waitingForSignature: '/dropoff/vehicle_reservations/{id}/waiting_for_signature',
    notifyRider: '/dropoff/vehicle_reservations/{vehicle_reservation_id}/contract/notify',
    checkForCompiletion:
      '/dropoff/vehicle_reservations/{vehicle_reservation_id}/contract/check_contract_completion',
    riderInfo: '/dropoff/vehicle_reservations/{id}/rider_info', // for welcome screen
    bikeConditionReviewConfirmation:
      '/dropoff/vehicle_reservations/{vehicle_reservation_id}/bike_condition_review_confirmation', // Rider Screen
    riderBikeConditionAddDocument:
      '/dropoff/vehicle_reservations/{vehicle_reservation_id}/rider_bike_condition_documents',
    riderBikeConditionDeleteDocument:
      '/dropoff/vehicle_reservations/{vehicle_reservation_id}/rider_bike_condition_documents/{id}',
    riderBikeConditionEditDocument:
      '/dropoff/vehicle_reservations/{vehicle_reservation_id}/rider_bike_condition_documents/{id}',
    riderBikeConditionReview:
      '/dropoff/vehicle_reservations/{vehicle_reservation_id}/rider_bike_condition_review', // Owner Screen [GET - PUT]
    ownerBikeConditionAddDocument:
      '/dropoff/vehicle_reservations/{vehicle_reservation_id}/owner_bike_condition_documents',
    ownerBikeConditionDeleteDocument:
      '/dropoff/vehicle_reservations/{vehicle_reservation_id}/owner_bike_condition_documents/{id}',
    ownerBikeConditionEditDocument:
      '/dropoff/vehicle_reservations/{vehicle_reservation_id}/owner_bike_condition_documents/{id}',
    chargesAndReimbursement:
      '/dropoff/vehicle_reservations/{vehicle_reservation_id}/charges_and_reimbursement',
    addCharge: '/dropoff/vehicle_reservations/{vehicle_reservation_id}/charges',
    deleteUpdateCharge: '/dropoff/vehicle_reservations/{vehicle_reservation_id}/charges/{id}',
    reimbursement: '/dropoff/vehicle_reservations/{id}/reimbursement',

    contractConfirm: '/dropoff/vehicle_reservations/{vehicle_reservation_id}/contract/confirm',
    priceSummary: '/dropoff/vehicle_reservations/{vehicle_reservation_id}/price_summary',
    paymentSummary: '/dropoff/vehicle_reservations/{id}/quote_and_payment_summary',
    paymentHistory: '/vehicle_reservations/{vehicle_reservation_id}/payments',
    refundBalance: '/dropoff/vehicle_reservations/{vehicle_reservation_id}/refund_balance',
    riderRating: '/dropoff/vehicle_reservations/{vehicle_reservation_id}/rider_rating',
    state: '/dropoff/vehicle_reservations/{id}/state',
    rider: {
      signatureDocument:
        '/dropoff/vehicle_reservations/{vehicle_reservation_id}/contract/signature_document',
    },
    ownerRating: '/dropoff/vehicle_reservations/{vehicle_reservation_id}/owner_rating',
    tripIssues: '/dropoff/vehicle_reservations/{vehicle_reservation_id}/trip_issues/{id}',
    tripIssue: '/dropoff/vehicle_reservations/{vehicle_reservation_id}/trip_issues',
    withdrawContract: '/dropoff/vehicle_reservations/{id}/withdraw_contract',
    dispute: '/dropoff/vehicle_reservations/{vehicle_reservation_id}/dispute',
  },
  storeCredits: {
    validate: '/vehicle_reservations/{vehicle_reservation_id}/store_credits/validate',
  },
  pickup: {
    safetyCheck: {
      mileageFuelLevels:
        '/pickup/vehicle_reservations/{vehicle_reservation_id}/mileage_and_fuel_levels',
    },
    state: '/pickup/vehicle_reservations/{id}/state',
    rider: {
      signatureDocument:
        '/pickup/vehicle_reservations/{vehicle_reservation_id}/contract/signature_document',
    },
  },
  contract: {
    addDoc: '/pickup/vehicle_reservations/{vehicle_reservation_id}/contract/inspection_documents',
    deleteDoc:
      '/pickup/vehicle_reservations/{vehicle_reservation_id}/contract/inspection_documents/{id}',
    updateDesc:
      '/pickup/vehicle_reservations/{vehicle_reservation_id}/contract/inspection_documents/{id}',
  },
  tours: {
    search_widget: '/tours/search_widget',
  },
  userVehiclesList: {
    vehicles: '/vehicles',
    createDraftVehicle: '/vehicles',
    statesCount: '/states_count',
    deactivate: '/vehicles/{id}/deactivate',
    activate: '/vehicles/{id}/activate',
  },
  users: {
    forgotPassword: '/users/password',
    signIn: '/users/sign_in',
    signUp: '/users/sign_up',
    signOut: '/users/sign_out',
    sendEmail: '/users/send_email',
    sendSMS: '/users/send_sms',
    resendSMS: '/users/resend_sms',
    verifyPhone: '/users/verify_phone',
    verifyEmail: '/users/verify_email',
    verifyTimezone: '/users/verify_timezone',
    signViaOTP: '/users/sign_via_otp',
    thirdPartyAuth: '/users/third_party_authentication',
  },
  vrs: {
    lookup: '/vrs/lookup',
    associate: '/vehicle_reservations/{identifier}/checkin/associate-reservation',
    verification: '/vehicle_reservations/{identifier}/checkin/identity-verification',
    checkin: '/vehicle_reservations/{identifier}/checkin',
    postDriverLicense: '/vehicle_reservations/{identifier}/driver_license',
    deleteDriverLicense: '/vehicle_reservations/{identifier}/driver_license/{id}',
    postInsuranceProof: '/vehicle_reservations/{identifier}/insurance_proof',
    deleteInsuranceProof: '/vehicle_reservations/{identifier}/insurance_proof/{id}',
  },
  contactUs: '/contact_us',
  layout: '/v2/layout',
  me: '/me',
  newslettersSubscribe: '/newsletters_subscribe',
  reservation: {
    delete: '/reservation',
  },
  siteIndex: {
    siteIndex: '/siteindex',
    filteredSiteIndex: '/siteindex/{place}',
  },
  conversations: '/conversations',
  vehicles: {
    vehicle: '/vehicles/{id}',
    reviews: '/vehicles/{id}/reviews',
    reservationsIntervals: '/vehicles/{id}/reservations_intervals',
    bookingDetails: '/vehicles/{vehicle_id}/booking_details',
    availability: '/vehicles/{vehicle_id}/availability',
    vehicleReservations: '/vehicle_reservations',
  },
  bookingsManagement: {
    ownerList: '/owner/vehicle_reservations',
    ownerFilters: '/owner/vehicle_reservations/filters',
    ownerExportCSV: '/owner/vehicle_reservations/export_csv',
    tripsList: '/user/vehicle_reservations',
    reservationDetails: '/vehicle_reservations/{id}',
    checkout: '/vehicle_reservations/{id}/checkout',
    accept: '/vehicle_reservations/{id}/accept',
    decline: '/vehicle_reservations/{id}/decline',
    sendCheckInReminder: '/vehicle_reservations/{vehicle_reservation_id}/send_check_in_reminder',
    sendConfirmationReminder:
      '/vehicle_reservations/{vehicle_reservation_id}/send_confirmation_reminder',
    cancellation: {
      index: '/vehicle_reservations/{vehicle_reservation_id}/cancellation',
      reasons: '/vehicle_reservations/{vehicle_reservation_id}/cancellation/reasons',
      confirmation: '/vehicle_reservations/{vehicle_reservation_id}/cancellation/confirmation',
      reviewRefund: '/vehicle_reservations/{vehicle_reservation_id}/cancellation/review_refund',
      dispute: '/vehicle_reservations/{vehicle_reservation_id}/cancellation/dispute',
      accept: '/vehicle_reservations/{vehicle_reservation_id}/cancellation/accept',
    },
    edit: '/vehicle_reservations/{id}/edit',
    update: '/vehicle_reservations/{id}',
    bookingDetails: '/vehicle_reservations/{vehicle_reservation_id}/booking_details',
    availability: '/vehicle_reservations/{vehicle_reservation_id}/availability',
    refund: '/vehicle_reservations/{vehicle_reservation_id}/refund_balance',
  },
  riderDetailsReview: {
    view: '/vehicle_reservations/{vehicle_reservation_id}/rider_details_review',
    accept: '/vehicle_reservations/{vehicle_reservation_id}/rider_details_review/accept',
    decline: '/vehicle_reservations/{vehicle_reservation_id}/rider_details_review/decline',
  },
  ReportTripIssue: {
    new: '/vehicle_reservations/{id}/trip_issues/new',
    report: '/vehicle_reservations/{id}/trip_issues',
  },
  checkout: {
    riderInfo: '/vehicle_reservations/{vehicle_reservation_id}/riders/{id}',
    optionalUpsells: '/vehicle_reservations/{vehicle_reservation_id}/optional_upsells',
    byCreditCard: '/vehicle_reservations/{vehicle_reservation_id}/payments/by_credit_card',
    byStoreCredit: '/vehicle_reservations/{vehicle_reservation_id}/payments/by_store_credit',
    confirm: '/vehicle_reservations/{id}/confirm',
    checkValidity: '/vehicle_reservations/{vehicle_reservation_id}/check_validity',
  },
  confirmation: '/vehicle_reservations/{vehicle_reservation_id}/confirmation',
  bikeUpload: {
    draftState: '/bike_upload/vehicles/{id}/draft_state',
    vehicleDelete: '/vehicles/{id}',
    information: '/owner/information',
    typeOfEntity: '/owner/type_of_entity',
    driverLicense: '/owner/driver_license',
    licenseImages: '/owner/license_images',
    nearbyRides: '/owner/nearby_rides',
    nearbyRidesUpdate: '/owner/nearby_rides/{id}',
    availability: '/bike_upload/vehicles/{id}/availability',
    rideImages: {
      post: 'owner/rides/{ride_id}/images',
      delete: 'owner/rides/{ride_id}/images/{id}',
    },
    deleteLicenseImages: '/owner/license_images/{id}',
    prices: '/bike_upload/vehicles/{id}/prices',
    submit: '/bike_upload/vehicles/{id}/final_details',
    location: '/bike_upload/vehicles/{id}/location',
    condition: '/bike_upload/vehicles/{id}/condition',
    mainDetails: {
      index: '/bike_upload/vehicles/{id}/main_details',
      vinCheck: '/bike_upload/vehicles/{id}/main_details/vin_check',
      registrationDocuments: {
        post: '/bike_upload/vehicles/{id}/main_details/registration_documents',
        delete: '/bike_upload/vehicles/{id}/main_details/registration_documents/{document_id}',
      },
      insuranceDocuments: {
        post: '/bike_upload/vehicles/{id}/main_details/insurance_documents',
        delete: '/bike_upload/vehicles/{id}/main_details/insurance_documents/{document_id}',
      },
    },
    photosGet: '/bike_upload/vehicles/{id}/photos',
    photosPut: '/bike_upload/vehicles/{id}/photos',
    sort: '/bike_upload/vehicles/{id}/photos/sort',
    upload: '/bike_upload/vehicles/{id}/photos/upload',
    delete: '/bike_upload/vehicles/{id}/photos/delete',
  },
  regions: '/regions',
  models: '/models',
  geographies: '/geographies',
  payments: {
    creditCards: '/credit_cards',
  },
  bookings: '/owner/vehicle_reservations',
  bookingsFilters: '/owner/vehicle_reservations/filters',
  exportBookingsCSV: '/owner/vehicle_reservations/export_csv',
  trips: '/user/vehicle_reservations',
  reservationDetails: '/vehicle_reservations/{id}',
  communication: {
    findOrCreate: '/talkjs_conversations/find_or_create',
  },
  checkinConfirmation: '/vehicle_reservations/{identifier}/checkin_confirmation',
  vehicle_reservations: {
    preride_documents: {
      preride_photos: '/vehicle_reservations/:id/preride_photos',
      safety_inspection_report: '/vehicle_reservations/:id/safety_inspection_report',
      rental_contract: '/vehicle_reservations/:id/rental_contract',
      rental_agreement_terms: '/vehicle_reservations/:id/rental_agreement_terms',
      insurance_policy_card: '/vehicle_reservations/:id/insurance_policy_card',
    },
    postride_documents: {
      postride_photos: '/vehicle_reservations/:id/postride_photos',
      dropoff_receipt: '/vehicle_reservations/:id/dropoff_receipt',
    },
  },
  rentals: {
    pickup_places_search: '/pickup_places',
    pickupPlaces: '/v2/pickup_places',
    nearbyDropoffLocations: '/nearby_dropoff_locations',
  },
  profile: {
    private_owner: '/private_owner',
    private_renter: '/private_renter',
    commercial_owner: '/commercial_owner',
    owners: '/owners/{id}',
    ownersVehicles: '/owners/{owner_id}/vehicles',
    ownersRiderReviews: '/owners/{owner_id}/rider_reviews',
  },
  languages: '/languages',
  vehicleModels: '/vehicle_models',
  vehicleMakes: '/vehicle_makes',
  detectSlugs: '/detect_slugs',
  geo: {
    rentals: '/rentals',
  },
  rentalWidget: '/er/rental_widget',
  reservations: {
    create: '/er/reservations',
  },
  here: 'https://geocoder.ls.hereapi.com/6.2/geocode.json?countryfocus=usa',
  gears: {
    activate: '/gears/{gear_id}/activate',
    deactivate: '/gears/{gear_id}/deactivate',
    list: '/gears',
    edit: '/gears/{gear_id}/edit',
    update: '/gears/{gear_id}',
    delete: '/gears/{gear_id}',
    new: {
      get: '/gears/new',
      post: '/gears',
    },
  },
  faqs: '/faqs',
  informationPages: '/information_pages',
};
