import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';

import GoogleIcon from 'components/shared/icons/GoogleIcon';
import FacebookIcon from 'components/shared/icons/FacebookIcon';

import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import axios, { API } from 'config/axios';
import { useLayoutSnackbar, useLayoutLoadingSpinner } from 'components/shared/Layout';
import useHandleResponse from 'utils/useHandleResponse';
import { Router, withTranslation } from 'config/i18n';
import clsx from 'clsx';
import useSSONavigate from 'common/hooks/useSSONavigate';

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const useStyles = makeStyles((theme) => ({
  otherLoginBtn: {
    letterSpacing: theme.spacing(0.125),
    textTransform: 'uppercase',
  },
}));

const {
  publicRuntimeConfig: { envVars },
} = getConfig();

const WindowOpener = dynamic(() => import('components/shared/WindowOpener'), {
  ssr: false,
});

const SocialLogin = (props) => {
  const { t, classes: styles = {} } = props;
  const classes = useStyles();
  const [, setLoading] = useLayoutLoadingSpinner();
  const [snackbar, setSnackbar] = useLayoutSnackbar();
  const { handleError } = useHandleResponse();
  const { moveToERSSOPage } = useSSONavigate();

  const randomString = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  const [providerData, SetProviderData] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [stateCode, SetStateCode] = useState(randomString);

  // eslint-disable-next-line max-len
  const GoogleUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${envVars.GOOGLE_KEY}&response_type=code&scope=profile email&redirect_uri=${window.location.origin}/auth/google&state=${stateCode}`;
  const fbUrl = `https://www.facebook.com/v12.0/dialog/oauth?
client_id=${envVars.FB_KEY}
&redirect_uri=${window.location.origin}/auth/facebook&state=${stateCode}&scope=email,public_profile`;

  const handleSignIn = (authenticityToken) => {
    axios
      .post(API.users.thirdPartyAuth, {
        authenticity_token: authenticityToken,
        authorization_token: providerData.code,
        provider: providerData.provider,
      })
      .then((res) => {
        let path = null;
        if (Router?.query?.return_url) {
          path = Router.query.return_url;
        } else {
          path = '/';
        }

        moveToERSSOPage(res.data.otp_token, path);

        setLoading(false);
      })
      .catch((error) => {
        try {
          switch (error.response.status) {
            case 401:
              setSnackbar({
                ...snackbar,
                open: true,
                severity: 'error',
                message: error.response.data.message,
              });
              break;
            default:
              handleError(error);
          }
        } catch (e) {
          handleError(error);
        }
        setLoading(false);
      });
  };

  const getToken = () => {
    setLoading(true);
    axios
      .get(API.authenticityToken)
      .then((res) => {
        handleSignIn(res.data.authenticity_token);
      })
      .catch((error) => {
        handleError(error);
        setLoading(false);
      });
  };
  const providerResponse = (err, res) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.log(res, 'error');
    }

    if (stateCode == res.state) {
      SetProviderData(res);
    } else {
      // eslint-disable-next-line no-alert
      alert('something wrong please try again ');
    }
  };
  useEffect(() => {
    providerData && getToken();
  }, [providerData]);
  return (
    <Box className={clsx(styles.root)}>
      <Box mb={2}>
        <WindowOpener url={GoogleUrl} bridge={providerResponse} width={600} height={600}>
          <Button
            className={clsx(classes.otherLoginBtn, styles.SLGoogle)}
            fullWidth
            variant="outlined"
            color="secondary"
          >
            <Box component="span" lineHeight="10px" mr={1}>
              <GoogleIcon />
            </Box>
            {t('fe_es_layout:signin.sign_in_with_google')}
          </Button>
        </WindowOpener>
      </Box>
      <Box mb={2}>
        <WindowOpener url={fbUrl} bridge={providerResponse} width={600} height={600}>
          <Button
            className={clsx(classes.otherLoginBtn, styles.SLFacebook)}
            fullWidth
            variant="outlined"
            color="secondary"
          >
            <Box component="span" lineHeight="10px" mr={1}>
              <FacebookIcon />
            </Box>
            {t('fe_es_layout:signin.sign_in_with_facebook')}
          </Button>
        </WindowOpener>
      </Box>
    </Box>
  );
};

export default withTranslation('fe_es_auth')(SocialLogin);
